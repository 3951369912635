import React, { useEffect, useState } from 'react';
import APILink from './APILink.js'

const Sidebar = props => {
    let organizationConfig = props.organizationConfig
    let apiLinks = []
    const [search, setSearch] = useState("")

    if (props.definitionList === null) {
        props.getOrganizationData(organizationConfig.orgName)
    } else {
        for (let i = 0; i < props.definitionList.length; i++) {

            apiLinks.push(
                <APILink
                    key={i}
                    rowNum={i}
                    apiLinkData={props.definitionList[i]}
                    updateDefinitionLink={props.updateDefinitionLink}
                />
            )

        }
    }



    return (
        <div className="side-bar">
            <div className="side-bar-header">
                <h1>{organizationConfig.displayName}</h1>
            </div>
            <div className='searchbox'>
                <input type="text" name="search" placeholder='Search Apis' value={search} onChange={(event) => {
                    setSearch(event.target.value)
                }} />
            </div>
            <div className="side-bar-body">
                <h3>API DOCS</h3>
                {search.trim() === "" ?
                    <>{apiLinks}</>
                    :
                    <>
                        {props.definitionList.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())).map((item2, index) =>
                            <APILink
                                key={index}
                                rowNum={index}
                                apiLinkData={item2}
                                updateDefinitionLink={props.updateDefinitionLink}
                            />)}
                    </>
                }
            </div>
        </div>
    )
}

export default Sidebar;